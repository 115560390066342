<template>
  <div style="height: 500px; width: 100%" class="mt-3 rounded shadow-sm">
    <l-map
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      style="height: 100%"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
      <LPolyline color="green" :lat-lngs="getRoute" />
      <markerItem
        :lat="$parent.detail.planning.vehiclesLatitude"
        :long="$parent.detail.planning.vehiclesLongitude"
        :title="$parent.detail.planning.plaque"
        :imgUrl="'https://www.flaticon.com/svg/static/icons/svg/726/726455.svg'"
      />

      <markerItem
        :lat="
          $parent.detail.planning.stateId == 2
            ? $parent.detail.planning.outpointLatitude
            : $parent.detail.planning.customerLatitude
        "
        :long="
          $parent.detail.planning.stateId == 2
            ? $parent.detail.planning.outpointLongitude
            : $parent.detail.planning.customerLongitude
        "
        :title="
          $parent.detail.planning.stateId == 2
            ? $parent.detail.planning.name +' / Dolum Noktası'
            : $parent.detail.planning.customerName +' / Müşteri'
        "
        :imgUrl="'https://www.flaticon.com/svg/static/icons/svg/2164/2164870.svg'"
      />
    </l-map>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LPolyline } from "vue2-leaflet";
import Axios from "axios";
import "leaflet/dist/leaflet.css";
import markerItem from "./markerItem";
export default {
  components: {
    LMap,
    LTileLayer,
    LPolyline,
    markerItem,
  },
  data() {
    return {
      zoom: 12,
      center: latLng(47.41322, -1.219482),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 12,
      currentCenter: latLng(47.41322, -1.219482),
      mapOptions: {
        zoomSnap: 0.5,
      },
      detail: null,
      show: false,
      load: false,
    };
  },
  methods: {
    getList(targetLatitude, targetLongitude) {
      this.load = false;
       if(this.$parent.detail.planning.vehiclesLatitude>0 && this.$parent.detail.planning.vehiclesLongitude>0)
       {
            Axios.get(
              "https://api.tomtom.com/routing/1/calculateRoute/" +
                [
                  this.$parent.detail.planning.vehiclesLatitude,
                  this.$parent.detail.planning.vehiclesLongitude,
                ] +
                ":" +
                [targetLatitude, targetLongitude] +
                "/json?instructionsType=text&language=en-US&vehicleHeading=90&sectionType=traffic&report=effectiveSettings&routeType=eco&traffic=true&avoid=unpavedRoads&travelMode=truck&vehicleMaxSpeed=120&vehicleCommercial=false&vehicleEngineType=combustion&key=G19dPEKGKp0pgyTaS1PQNjNNtAHePBRO"
            )
              .then((ress) => {
                this.detail = ress.data;

                this.load = true;
              })
              .catch((err) => {
                alert(err);
              });
       }else{
         this.$swal.fire({
           icon:'warning',
           title:'UYARI !',
           text:'Bulunduğunuz araç herhangi bir takip sistemi tarafından takip edilmediği için rota oluşturulamadı'
         });
       }

    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
    showPosition(position) {
      this.$parent.detail.planning.vehiclesLatitude = position.coords.latitude;
      this.$parent.detail.planning.vehiclesLongitude =
        position.coords.longitude;
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      }
    },
  },
  created() {
    const stateId = parseInt(this.$parent.detail.planning.stateId);
    if (stateId == 2) {
      this.getList(
        this.$parent.detail.planning.outpointLatitude,
        this.$parent.detail.planning.outpointLongitude
      );
      
      if(this.$parent.detail.planning.vehiclesLatitude>0 && this.$parent.detail.planning.vehiclesLongitude>0)
      {
        this.center = [
          this.$parent.detail.planning.vehiclesLatitude,
          this.$parent.detail.planning.vehiclesLongitude,
        ];
      }else{
        this.center = [
          this.$parent.detail.planning.outpointLatitude,
          this.$parent.detail.planning.outpointLongitude,
        ];
      }

    } else if (stateId > 2 && stateId < 8) {
      this.getList(
        this.$parent.detail.planning.customerLatitude,
        this.$parent.detail.planning.customerLongitude
      );

       if(this.$parent.detail.planning.vehiclesLatitude>0 && this.$parent.detail.planning.vehiclesLongitude>0)
      {
        this.center = [
          this.$parent.detail.planning.vehiclesLatitude,
          this.$parent.detail.planning.vehiclesLongitude,
        ];
      }else{
        this.center = [
          this.$parent.detail.planning.customerLatitude,
          this.$parent.detail.planning.customerLongitude,
        ];
      }

    }
    
      this.currentCenter = this.center;



    setInterval(() => {
      this.getLocation();
    }, 5000);
  },
  computed: {
    getRoute() {
      return this.load
        ? this.detail.routes[0].legs[0].points.map((r) => {
            return [r.latitude, r.longitude];
          })
        : [];
    },
  },
};
</script>

<style></style>
