<template>
  <div class="col-12 border-bottom shadow-sm p-3 mt-3 rounded bg-dark-light" >
    <h6  :class="danger ? 'text-danger font-weight-bold' : 'text-primary'">{{title}}</h6>
    <h5 class="mt-2 text-uppercase">{{explanation}}</h5>
    <a :href="buttonUrl" title="Görüntüle" target="_blank" class="btn btn-primary btn-sm rounded text-uppercase" v-if="showButton">
      <i class="fas fa-eye    "></i>
      {{buttonText}}
    </a>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    title: String,
    explanation: String,
    showButton:Boolean,
    buttonText:String,
    buttonUrl:String,
    danger:Boolean
  }
}
</script>

