<template>
  <div class="col-12 " >
  <div class="col-sm-12 text-center p-4" v-if="detail.planning.stateId==9">
    <i class="fas fa-exclamation-triangle  fa-3x text-danger"></i> <br>
    <h3 class="text-uppercase mt-2 font-weight-bold">Sevkiyat İptal EDİLDİ !</h3>
  </div>
  <div class="col-sm-12 text-center mt-3 p-4" v-if="detail.planning.stateId==8">
    <i class="fas fa-check-circle  fa-3x text-success"></i> <br>
    <h3 class="text-uppercase mt-2 font-weight-bold">Sevkiyat Tamamlandı !</h3>
  </div>
  <div class="col-12" v-if="detail.planning.stateId!=9">
    <div class="col-12 bg-dark-light p-4 text-center rounded shadow-sm">
      <h2 class="font-weight-bold text-primary">SEFERİ</h2>
      <h5 class="font-weight-bold">Sürücü Bilgilendirme Ekranı</h5>
    </div>
    <detailItem
      title="Sevkiyat Numarası"
      :explanation="detail.planning.transitionId"
    />
    <detailItem title="Sürücü" :explanation="detail.planning.personnelName" />
    <detailItem title="Araç Plakası" :explanation="detail.planning.plaque" />
    <detailItem
      title="Müşteri Adı"
      :explanation="detail.planning.customerName"
    />
    <detailItem
      title="Ürün Adı"
      :explanation="detail.planning.productName"
      showButton
      buttonText="Sds dosyasını Görüntüle"
      :buttonUrl="storegeUrl + detail.planning.sdsFileUrl"
    />
    <div class="col-12 shadow-sm border-bottom mt-3 p-3 rounded bg-dark-light">
      <h6 class="text-danger font-weight-bold d-inline">
        Takılması Gereken Tabelalar
      </h6>
      <p class="border-bottom pb-2" style="font-size: 12px">
        {{ detail.tables.length }} Adet gerekli table listelendi
      </p>
      <div class="row" style="max-height: 250px; overflow-y: scroll">
        <div
          class="col-sm-6 col-md-3 mt-2"
          v-for="item in detail.tables"
          :key="item.id"
        >
          <img class="w-100 rounded" :src="storegeUrl + item.imageUrl" alt="" />
        </div>
      </div>
    </div>

    <div class="col-12 shadow-sm border-bottom mt-3 p-3 rounded bg-dark-light">
      <h6 class="text-danger font-weight-bold d-inline">GEREKSİNİMLER</h6>
      <p class="border-bottom pb-2" style="font-size: 12px">
        {{ detail.requiments.length }} Adet gereksinim listelendi
      </p>
      <div class="row" style="max-height: 250px; overflow-y: scroll">
        <ul>
          <li
            class="p-2 border-bottom text-uppercase"
            v-for="item in detail.requiments"
            :key="item.id"
          >
            {{ item.explanation }}
            <span class="badge badge-primary">{{
              item.type == "2" ? "Ürün Gereksinimi" : "Müşteri Gereksinimi"
            }}</span>
          </li>
        </ul>
      </div>
    </div>

    <detailItem
      danger
      title="Dolum Noktası"
      :explanation="detail.planning.name"
    />
    <detailItem
      danger
      title="Dolum Zamanı"
      :explanation="
        dateControl(detail.planning.fillingDate) +
        ' / ' +
        detail.planning.fillingTime
      "
    />
    <detailItem
      danger
      title="Teslim Zamanı"
      :explanation="
        dateControl(detail.planning.deliveryDate) +
        ' / ' +
        detail.planning.deliveryTime
      "
    />
    <detailModal />
  </div>

  </div>
</template>

<script>
import Axios from "axios";
import detailItem from "../components/detailItem";
import detailModal from "./components/detailModal";
export default {
  name: "Home",
  components: {
    detailItem,
    detailModal,
  },
  data() {
    return {
      detail: {
        planning:{
          stateId:0
        }
      },
      storegeUrl: "https://api.oldukcayenilik.com/seferiWeb/",
    };
  },
  methods: {
    getList() {
      Axios.post(
        "https://api.oldukcayenilik.com/seferiWeb/index.php/planning/get/personnel/detail",
        {
          token: this.$route.params.token,
          planId: this.$route.params.planId,
          driverId: this.$route.params.driverId,
        }
      )
        .then((res) => {
          if (res.data.state) {
            this.detail = res.data.data;
            if (this.detail == undefined) {
              this.$router.push("/pageError");
            }
          } else {
            this.$router.push("/pageError");
          }
        })
        .catch((acc) => {
          alert(acc);
        });
    },
    dateControl(val) {
      const getParamDate = new Date(val);
      const nowDate = new Date();
      let yesterDay = new Date();
      yesterDay.setDate(yesterDay.getDate() - 1);

      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      if (
        getParamDate.toLocaleDateString("en-US") ==
        nowDate.toLocaleDateString("en-US")
      ) {
        return "Bu Gün";
      } else if (
        getParamDate.toLocaleDateString("en-US") ==
        yesterDay.toLocaleDateString("en-US")
      ) {
        return "Dün";
      } else if (
        getParamDate.toLocaleDateString("en-US") ==
        tomorrow.toLocaleDateString("en-US")
      ) {
        return "Yarın";
      } else {
        return getParamDate.toLocaleDateString("en-US");
      }
    }
  },
  created() {
    this.getList();
  },
};
</script>
