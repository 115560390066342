<template>
  <l-marker :lat-lng="[lat, long]">
    <l-icon>
      <img :src="imgUrl" width="60px" alt="" />
      <label id="title" class="mb-0 mt-2">{{ title }}</label>
    </l-icon>
  </l-marker>
</template>

<script>
import { LMarker, LIcon } from "vue2-leaflet";
import { latLng, icon } from "leaflet";

export default {
  props: ["lat", "long", "title", "imgUrl"],
  components: {
    LMarker,
    LIcon,
  },
  data() {
    return {
      icon: icon({
        iconUrl: this.imgUrl,
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      }),
    };
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
  },
  methods: {
    getPosition() {
      return latLng(this.lat, this.long);
    },
  },
};
</script>

<style>
.someExtraClass {
  padding: 5px;
  border-radius: 20px 20px 20px 20px;
  text-align: center;
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
}
#title {
  position: absolute;
  top: 0px;
  display: block;
  max-width: 150px !important;
  padding: 5px;
  right: 100%;
  background: red;
  font-weight: bold;
  color: white;
  border-radius: 2px;
}
</style>
