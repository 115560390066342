<template>
  <div class="col-12 text-center h-100 bg-danger p-4">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <span>
        <h1 class="jumbotron text-center bg-light">404 ERROR</h1>
        <h4 class="text-white font-weight-bold text-capitalize">
          Sayfa Bulunamadı !
        </h4>
      </span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
